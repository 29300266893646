.statisics {
  margin: 0 0 60px;
}
.statisics .boxinner {
  height: 100%;
}
.statisics .boxinner h2 {
  font-size: 28px;
  padding: 0;
  margin: 0px 0px 40px;
  display: block;
}
.statisics .boxinner .box {
  text-align: center;
  margin: 0 0 30px;
  border-radius: 5px;
  padding: 30px 10px;
  border: 1px solid #125d60;
  box-shadow: 3px 3px 0px #125d60;
}
.statisics .boxinner .box h3 {
  font-size: 18px;
  min-height: 44px;
  color: #02ffff;
  margin: 0 0 15px;
}
.statisics .boxinner .box h5 {
  font-size: 18px;
  color: #02ffff;
  margin: 0 0 15px;
}
.statisics .boxinner .box p {
  margin: 0 0 15px;
}
.statisics .boxinner .box strong {
  margin: 0 0 15px;
  display: block;
  color: #125d60;
}
.statisics .boxinner.rightinfo .box {
  background-color: #125d60;
  border-color: #135e61;
  box-shadow: none;
}
.statisics .boxinner.rightinfo .box strong {
  color: #ffffff;
}
.statisics .copyReferralLink {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .statisics .boxinner h2 {
    text-align: center;
    color: #02ffef;
  }
  .statisics .boxinner.leftinfo {
    margin-bottom: 30px;
  }
}/*# sourceMappingURL=Statisics.css.map */