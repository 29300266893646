.publicSale {
    margin: 0px 0px 60px;

    .infobox {
        margin-bottom: 15px;

        h1 {
            margin: 0 0 30px;
            font-size: 48px;
            font-weight: bold;
            text-shadow: -2px -2px 0px #02ffff;
        }

        ul {
            padding: 0px;
            margin: 0px 0px 30px;
            list-style: none;

            li {
                margin-bottom: 15px;
            }
        }

        h3 {
            font-size: 20px;
            margin: 0 0 10px;
        }

        .copybox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: #125d60;
            max-width: max-content;
            padding: 12px 15px;
            border-radius: 5px;
            color: #02ffff;

            p {
                margin: 0px;
            }

            svg {
                font-size: 24px;
                margin-left: 20px;
                cursor: pointer;
                fill: #fff43c;
            }
        }
    }

    .imageBox {
        text-align: center;

        img {
            max-width: 500px;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 767px) {
    .publicSale {
        text-align: center;
    }
    .publicSale .infobox .copybox {
        margin: 20px auto 0px;
    }
}