.navbar {
    height: 60px;
    background-color: #fef7e5;
    position: relative;
}

.menu-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    width: 30px;
    transform: translateY(-50%);

    img {
        filter: invert(1);
    }
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0px;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 30px;
}

.nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
}

.nav-elements ul a:hover {
    color: #02ffff;
}

.nav-elements ul a:hover::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #02ffff;
}

nav.navbar {
    position: absolute;
    top: 60px;
    left: 50%;
    width: 1320px;
    max-width: calc(100% - 10vw);
    z-index: 3;
    height: 115px;
    background-color: transparent;
    transform: translateX(-50%);
    justify-content: space-between;

    .logo {
        flex: 0 0 10vw;
        max-width: 140px;
        min-width: 80px;
    }

    .navbar_inner {
        width: 100%;
        position: relative;
    }

    .balancebox {
        ul {
            margin: 0px;
            padding: 0px;
            display: flex;
            align-items: center;
            list-style: none;

            li {
                margin-left: 15px;
                font-weight: 400;

                strong {
                    font-weight: bold;
                    margin-right: 10px;
                }

                span {
                    background-color: #02ffff;
                    color: #000000;
                    display: block;
                    padding: 10px 15px;
                    border-radius: 50px;
                    cursor: pointer;
                }
            }
        }
    }
}
@media (max-width: 1180px){
    .nav-elements ul {
        padding: 0;
    }
    .nav-elements ul li:not(:last-child) {
        margin-right: 15px;
    }
    .nav-elements ul a {
        font-size: 14px;
    }
}
@media (max-width: 992px) {
    .balancebox {
        margin-right: 45px;
        margin-left: auto;
    }

    nav.navbar {
        top: 20px;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        right: 0px;
        top: 100%;
        width: 100%;
        height: auto;
        transition: all 0.3s ease-in;
        overflow: hidden;
        max-width: 320px;
        transform: scale(0);
        transform-origin: top right;
    }

    .nav-elements.active {
        transform: scale(1);
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        background-color: #02ffff;
        border-radius: 5px;
    }

    .nav-elements ul li {
        margin-right: 0px;
    }

    .nav-elements ul li:not(:last-child) {
        margin-right: 0px;
    }

    .nav-elements ul li a {
        padding: 15px;
        display: block;
        color: #000000;
        font-weight: bold;
        &:hover{
            color: #000000;
        }
    }
}
@media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 30px;
    }
    nav.navbar .balancebox ul li {
        margin-left: 4px;
        font-size: 14px;
    }

}
@media (max-width: 520px) {
    nav.navbar .balancebox ul li {
        margin-left: 4px;
        font-size: 14px;
    }
    nav.navbar .balancebox ul li span {
        padding: 8px 8px;
        font-size: 14px;
    }
    nav.navbar .balancebox ul li strong {
        margin-right: 5px;
    }
    nav.navbar .balancebox ul li:nth-last-child(1){
        margin-left: 8px;
        span.logoutSn{
            font-size: 0px;
            padding: 6px;
            width: 32px;
            height: 32px;
        }
        svg{
            width: 17px;
            height: 17px;
            display: block;
        }
    }
}

@media (max-width: 390px) {
    .menu-icon {
        right: 10px;
        width: 20px;
    }
    nav.navbar {
        top: 0px;
    }
    .publicSale .infobox h1 {
        font-size: 38px;
    }
    nav.navbar .balancebox ul li {
        margin-left: 4px;
        font-size: 10px;
    }
    nav.navbar .logo {
        max-width: 100px;
        min-width: 75px;
    }
}