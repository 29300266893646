@media only screen and (max-width: 992px) {
  .main {
    padding: 150px 2vw 5vw;
  }

  .oc_btn {
    padding: 10px 10px;
    min-width: auto;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .buttonGrp {
    text-align: center;
    margin-top: 15px;
  }

  .oc_container {
    max-width: calc(100% - 15px);
  }

  .oc_btn.copyRefferalCode {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 450px) {
  .buySell .buySellbox .oc_btn {
    display: block;
    width: 100%;
  }

  .buyOc .buyOcbox .oc_btn {
    display: block;
    width: 100%;
  }
}

@media (max-width: 390px) {
  .main {
    padding: 120px 2vw 5vw;
  }
}