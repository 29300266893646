*,
*::before,
*::after {
  box-sizing: border-box;
}

.bg-themedark {
  background-color: #125D60;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: white;
  text-align: left;
  background-color: #000000;
  word-break: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/backgif.gif);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -2;
  filter: blur(0px);
  opacity: 0.06;
  mix-blend-mode: difference;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0b3335;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #115C5F;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Orbitron", sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

.bg-themedark {
  background-color: #125D60;
}

.bg-themelight {
  background-color: #01FFFF;
}

.oc_row {
  display: flex;
  flex-wrap: wrap;
}

.align-items-flex-end {
  align-items: flex-end;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.align-items-flex-start {
  align-items: flex-start;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.oc_col6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 10px;
}

.oc_container {
  width: 1320px;
  max-width: calc(100% - 10vw);
  margin-left: auto;
  margin-right: auto;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.title {
  text-align: center;
  padding: 0;
  margin: 0px 0px 40px;
  border: none;
}
.title h2 {
  text-align: center;
  display: block;
  margin: 0px;
  color: #02ffff;
  font-size: 28px;
  text-transform: capitalize;
}

.buttonGrp {
  text-align: left;
  margin-top: 15px;
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.oc_btn {
  background-color: #02ffff;
  padding: 15px 20px;
  border-radius: 5px;
  margin: 0 7.5px 15px;
  min-width: 180px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
}
.oc_btn:hover {
  opacity: 0.8;
}
.oc_btn:nth-last-child(1) {
  background-color: #262626;
  color: #ffffff;
  border: 1px solid #ffffff;
  font-weight: 400;
}
.oc_btn.copyRefferalCode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}
.oc_btn.copyRefferalCode img {
  height: auto;
  width: auto;
  margin-left: 10px;
  max-height: 19px;
  max-width: 20px;
}

.borderboxmain {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: -1;
}
.borderboxmain img {
  width: 97vw;
  height: auto;
  display: block;
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
}
.borderboxmain img.linetopleft {
  top: 1vw;
  left: 1vw;
  -o-object-position: left;
     object-position: left;
}
.borderboxmain img.linebottomright {
  bottom: 1vw;
  right: 1vw;
  -o-object-position: right;
     object-position: right;
}

.main {
  padding: 200px 5vw 5vw;
  position: relative;
  min-height: 1000px;
}

.tooltipIcon {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-weight: bold;
  border: none;
  margin-left: 6px;
  font-size: 16px;
  line-height: 1;
}

@media only screen and (max-width: 992px) {
  .main {
    padding: 150px 2vw 5vw;
  }
  .oc_btn {
    padding: 10px 10px;
    min-width: auto;
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .buttonGrp {
    text-align: center;
    margin-top: 15px;
  }
  .oc_container {
    max-width: calc(100% - 15px);
  }
  .oc_btn.copyRefferalCode {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 450px) {
  .buySell .buySellbox .oc_btn {
    display: block;
    width: 100%;
  }
  .buyOc .buyOcbox .oc_btn {
    display: block;
    width: 100%;
  }
}
@media (max-width: 390px) {
  .main {
    padding: 120px 2vw 5vw;
  }
}/*# sourceMappingURL=style.css.map */