*,
*::before,
*::after {
    box-sizing: border-box;
}

.bg-themedark {
    background-color: #125D60;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    color: white;
    text-align: left;
    background-color: #000000;
    word-break: break-word;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../images/backgif.gif);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -2;
        filter: blur(0px);
        opacity: 0.06;
        mix-blend-mode: difference;
    }
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #0b3335;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #115C5F;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Orbitron', sans-serif;
}

img {
    max-width: 100%;
    height: auto;
}

.bg-themedark {
    background-color: #125D60;
}

.bg-themelight {
    background-color: #01FFFF;
}

.oc_row {
    display: flex;
    flex-wrap: wrap;
}

.align-items-flex-end {
    align-items: flex-end;
}

.justify-content-flex-end {
    justify-content: flex-end;
}

.align-items-flex-start {
    align-items: flex-start;
}

.justify-content-flex-start {
    justify-content: flex-start;
}

.oc_col6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 10px;
}

.oc_container {
    width: 1320px;
    max-width: calc(100% - 10vw);
    margin-left: auto;
    margin-right: auto;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.title {
    text-align: center;
    padding: 0;
    margin: 0px 0px 40px;
    border: none;

    h2 {
        text-align: center;
        display: block;
        margin: 0px;
        color: #02ffff;
        font-size: 28px;
        text-transform: capitalize;
    }
}

.buttonGrp {
    text-align: left;
    margin-top: 15px;
    margin-left: -7.5px;
    margin-right: -7.5px;

}

.oc_btn {
    background-color: #02ffff;
    padding: 15px 20px;
    border-radius: 5px;
    margin: 0 7.5px 15px;
    min-width: 180px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    border: 1px solid transparent;

    &:hover {
        opacity: 0.8;
    }

    &:nth-last-child(1) {
        background-color: #262626;
        color: #ffffff;
        border: 1px solid #ffffff;
        font-weight: 400;
    }

    &.copyRefferalCode {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;

        img {
            height: auto;
            width: auto;
            margin-left: 10px;
            max-height: 19px;
            max-width: 20px;
        }
    }
}

// Start
.borderboxmain {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100%);
    min-height: 100vh;
    z-index: -1;

    img {
        width: calc(100vw - 3vw);
        height: auto;
        display: block;
        position: absolute;
        object-fit: contain;

        &.linetopleft {
            top: 1vw;
            left: 1vw;
            object-position: left;

        }

        &.linebottomright {
            bottom: 1vw;
            right: 1vw;
            object-position: right;

        }
    }
}

.main {
    padding: 200px 5vw 5vw;
    position: relative;
    min-height: 1000px;
}

.tooltipIcon {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-weight: bold;
    border: none;
    margin-left: 6px;
    font-size: 16px;
    line-height: 1;

}

// Media Query Css

@import 'media';