.projects {
    margin: 0 0 60px;

    .countBox {
        .countinbox {
            border: 1px solid #125d60;
            border-radius: 15px 0px 15px 0px;
            padding: 15px;
            height: 100%;
            position: relative;
        }

        svg {
            height: 30px;
            display: block;
            margin-bottom: 15px;

            path {
                fill: #01FFFF;
            }
        }

        h3 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0px;
            color: #01FFFF;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            margin: 0px 0px 8px;
        }
    }

    .col-sm-6 {
        padding: 15px;
    }

    .listBoxInner {
        border: 1px solid #008080;
        border-radius: 0px 10px 0px 10px;
        padding: 20px;
        position: relative;
        margin-bottom: 30px;

        .profileInfo {
            align-items: center;
            justify-content: space-between;
            margin: 0px;
        }

        .profilePic {
            flex: 0 0 56px;
            max-width: 56px;
            height: 56px;
            border-radius: 50%;
            margin-right: 15px;
            padding: 0px;
        }

        .profileInfo {
            ul {
                padding: 0px;
                margin: 0px;
                list-style: none;
                flex: 0 0 calc(100% - 71px);
                max-width: calc(100% - 71px);
                text-align: right;

                li {
                    display: none;
                    text-align: center;
                    padding: 5px 10px;

                    border-radius: 5px;
                    font-size: 12px;
                    font-weight: 500;

                    span {
                        width: 8px;
                        height: 8px;
                        border-radius: 50px;
                        display: inline-block;
                        margin-right: 4px;
                    }

                    &.upcoming {
                        background-color: #001f1f;
                        color: #d29813;

                        span {
                            background-color: #d29813;
                        }
                    }

                    &.saleLive {
                        background-color: #d1fae5;
                        color: #10b981;

                        span {
                            background-color: #10b981;

                        }
                    }

                    &.saleEnded {
                        background-color: #ffeaef;
                        color: #ff3465;

                        span {
                            background-color: #ff3465;
                        }
                    }

                    &.active {
                        display: inline-block;
                    }
                }
            }
        }

        h2 {
            font-size: 22px;
            font-weight: 600;
            margin: 0;
            display: block;
        }

        .subtitle {
            font-size: 15px;
            font-weight: 600;
            color: #888;
            margin: 0px;
            display: block;
        }

        h3 {
            font-size: 15px;
            font-weight: 600;
            margin: 0;
            display: block;
        }

        strong {
            font-size: 20px;
            font-weight: 600;
            color: #01FFFF;
            margin: 5px 0px 10px;
            display: block;
        }

        .progressBar {
            margin: 3px 0 0px;
            font-size: 15px;
        }

        .progresLine {
            display: block;
            position: relative;
            display: inline-block;
            width: 100%;
            overflow: hidden;
            // background-color: #f5f5f5;
            background-color: rgba(72, 199, 116, 0.15);
            border-radius: 100px;
            height: 8px;

            span {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                background: rgb(72, 199, 116);
                border-radius: 100px;
            }
        }

        .tableBox {
            p {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 15px;
                font-weight: 400;
                color: #ffffff;
                margin: 0 0 5px;

                span {
                    font-size: 15px;
                    color: #888;
                    font-weight: 600;
                }

                &.ttl {
                    color: #888;
                    font-size: 13px;
                    margin: 0 0 15px;
                    font-weight: 600;

                    span {
                        font-size: 13px;
                    }
                }
            }
        }

        .dividerLine {
            display: block;
            margin-bottom: 20px;
            width: 100%;
            height: 1px;
            background-color: #282a2a;
            margin-top: 5px;
        }

        .saleTineView {
            align-items: center;
            margin: 0px;
            z-index: 2;
            position: relative;

            .timesSale {
                margin: 0px;
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(100% - 105px);
                max-width: calc(100% - 105px);
                margin-right: 15px;
                padding: 0px;
                font-size: 14px;

                span {
                    color: #01FFFF;
                    display: block;
                    font-size: 14px;
                    margin-top: 4px;
                    letter-spacing: 1.2px;
                    font-weight: 600;
                }
            }

            .btnView {
                flex: 0 0 90px;
                max-width: 90px;
                text-align: center;
                background-color: #115C5F;
                color: #ffffff;
                text-decoration: none;
                padding: 10px 5px;
                border-radius: 5px;
                text-transform: uppercase;
                letter-spacing: 0.5px;
            }

            .react-countdown {
                padding: 0px;
                margin: 0px;
                list-style: none;

                li {
                    display: inline-block;
                    margin-right: 4px;

                    &::after {
                        top: 5px;
                        font-size: 14px;
                        right: -7px;
                    }
                }

                .digit {
                    color: #01FFFF;
                    margin: 5px 0px 10px;
                    display: block;
                    font-size: 14px;
                    font-weight: bold;
                }

                .text {
                    display: none;
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: -6px;
            left: -6px;
            width: 65px;
            height: 75px;
            background-image: url('../../Assets/images/topBorder.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top left;
            opacity: 0.5;
            mix-blend-mode: difference;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: -4px;
            right: -4px;
            width: 70px;
            height: 50px;
            background-image: url('../../Assets/images/bottomBorder.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: bottom right;
            opacity: 0.5;
            mix-blend-mode: difference;
        }

        &:hover {
            border-color: #01FFFF;

            .saleTineView .btnView {
                background-color: #01FFFF;
                color: #001f1f;
                font-weight: 600;
            }

            &::before {
                opacity: 1;
                mix-blend-mode: unset;
            }

            &::after {
                opacity: 1;
                mix-blend-mode: unset;

            }
        }

        &.active {
            animation: blinkOcPublicBrn 1.5s infinite;
            transition: all 0.2s ease-in-out;
            box-shadow: none;
            text-shadow: none;

            @keyframes blinkOcPublicBrn {
                0% {
                    border-color: #125d60;
                    opacity: 1;
                    box-shadow: none;
                    text-shadow: none;
                }

                49% {
                    border-color: #02ffff;
                    color: #02ffff;
                    text-shadow:
                        -0.2rem -0.2rem 1rem #ffffff,
                        0.2rem 0.2rem 1rem #ffffff,
                        0 0 2rem #02ffff,
                        0 0 4rem #02ffff,
                        0 0 6rem #02ffff,
                        0 0 8rem #02ffff,
                        0 0 10rem #02ffff;
                    box-shadow:
                        0 0 .1rem #125d60,
                        inset 0 0 .1rem #125d60,
                        0 0 2rem #125d60,
                        inset 0 0 0rem #125d60,
                        0 0 1rem #125d60,
                        inset 0 0 1rem #02ffff;

                }

                50% {
                    border-color: #125d60;
                    opacity: 1;
                    box-shadow: none;
                    text-shadow: none;

                }
            }

            .infoBox {
                text-shadow: none;
            }
        }
    }

}

@media only screen and (max-width: 575px) {
    .projects .countBox .countinbox {
        margin-bottom: 20px;
        height: auto;
    }
}