.buyOc {
  margin: 0 0 60px;
}
.buyOc .buyOcbox {
  background-color: #000000;
  padding: 30px;
  border-radius: 5px;
  height: 100%;
  border: 1px solid #e1e140;
}
.buyOc .buyOcbox .topttl {
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}
.buyOc .buyOcbox .topttl span {
  font-size: 14px;
}
.buyOc .buyOcbox input {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.161);
  border: none;
  border-radius: 5px;
  margin-bottom: 8px;
  font-size: 20px;
  color: #ffffff;
}
.buyOc .buyOcbox .buttonGrp {
  text-align: center;
}
.buyOc .buyOcbox .oc_btn {
  min-width: auto;
}/*# sourceMappingURL=BuyOc.css.map */