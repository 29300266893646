.faqs {
    margin: 0 auto 60px;
    max-width: 1100px;

    .faq-row-wrapper {
        .faq-title {
            text-align: center;
            justify-content: center;
            padding: 0;
            margin: 0px 0px 40px;
            border: none;

            h2 {
                font-size: 28px;
            }
        }

        .faq-body {
            .faq-row {
                border: 1px solid #125d60;
                margin-bottom: 25px;
                padding: 15px;
                border-radius: 5px;
            }
        }
    }

}