.refDetails {
    margin: 0 auto 80px;
    max-width: calc(100% - 30px);
}

.refDetailsList {
    border-top: 4px double #125d60;
    border-bottom: 4px double #125d60;
    padding: 30px;
    border-radius: 5px;
    background-color: #030e0e;
    // box-shadow: 3px 3px 0px #125d60;
    position: relative;

    .table-responsive {
        overflow: auto;
        max-height: 335px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -30px;
        transform: translateY(-50%);
        width: 100px;
        height: 100%;
        max-height: 400px;
        background-image: url('../../Assets/images/tableLine.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;

    }

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -30px;
        transform: translateY(-50%) scaleX(-1);
        width: 100px;
        height: 100%;
        max-height: 400px;
        background-image: url('../../Assets/images/tableLine.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;

    }

    table {
        min-width: 700px;

        th {
            background-color: #125d60;
            padding: 15px;
            font-weight: 500;
            font-size: 18px;
        }

        td {
            font-size: 16px;
            padding: 10px 15px;
            border-bottom: 1px solid #125d60;
        }

        tr {
            &:nth-last-child(1) {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}
.claimBonus{
    .buttonGrp{
        margin: 0 auto;
        text-align: center;
        button{
            background-color: #125d60;
            color: #ffffff;
        }
    }
}

@media (max-width: 575px) {
    .balanceOverviewBox {
        padding: 20px;
    }
}