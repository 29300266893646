.buySell {
  margin: 0 auto 60px;
}
.buySell .buySellbox {
  background-color: #000000;
  padding: 30px;
  border-radius: 5px;
  height: 100%;
  border: 1px solid #e1e140;
}
.buySell .buySellbox .topttl {
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
  text-align: right;
}
.buySell .buySellbox .fillBox {
  display: block;
  width: 100%;
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.161);
  border: none;
  border-radius: 5px;
  margin-bottom: 8px;
  font-size: 20px;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}
.buySell .buySellbox .fillBox span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #02ffff;
}
.buySell .buySellbox .buttonGrp {
  text-align: center;
}
.buySell .buySellbox .oc_btn {
  min-width: auto;
  display: inline-block;
}/*# sourceMappingURL=BuySell.css.map */