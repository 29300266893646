.balanceOverview {
  margin: 0 auto 60px;
  max-width: 1100px;
}

.balanceOverviewBox {
  border: 4px double #125d60;
  padding: 50px;
  border-radius: 5px;
  background-color: #030e0e;
  box-shadow: 3px 3px 0px #125d60;
}
.balanceOverviewBox ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.balanceOverviewBox ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.balanceOverviewBox ul li p {
  margin: 0px;
}
.balanceOverviewBox ul li p span {
  font-size: 12px;
}
.balanceOverviewBox ul li strong {
  margin-left: 15px;
  color: #02ffff;
  flex: 0 0 auto;
}
.balanceOverviewBox ul li strong.countDown {
  letter-spacing: 2px;
}
.balanceOverviewBox .buttonGrp {
  text-align: center;
}

@media (max-width: 575px) {
  .balanceOverviewBox {
    padding: 20px;
  }
}/*# sourceMappingURL=BalanceOverview.css.map */