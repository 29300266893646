.balanceOverview {
    margin: 0 auto 60px;
    max-width: 1100px;
}

.balanceOverviewBox {
    border: 4px double #125d60;
    padding: 50px;
    border-radius: 5px;
    background-color: #030e0e;
    box-shadow: 3px 3px 0px #125d60;

    ul {
        margin: 0px;
        padding: 0px;
        list-style: none;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;

            p {
                margin: 0px;

                span {
                    font-size: 12px;
                }
            }

            strong {
                margin-left: 15px;
                color: #02ffff;
                flex: 0 0 auto;

                &.countDown {
                    letter-spacing: 2px;
                }
            }
        }
    }

    .buttonGrp {
        text-align: center;
    }
}

@media (max-width: 575px) {
    .balanceOverviewBox {
        padding: 20px;
    }
}