.projects {
  margin: 0 0 60px;
}
.projects .countBox .countinbox {
  border: 1px solid #125d60;
  border-radius: 15px 0px 15px 0px;
  padding: 15px;
  height: 100%;
  position: relative;
}
.projects .countBox svg {
  height: 30px;
  display: block;
  margin-bottom: 15px;
}
.projects .countBox svg path {
  fill: #01FFFF;
}
.projects .countBox h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #01FFFF;
}
.projects .countBox p {
  font-size: 14px;
  font-weight: 500;
  margin: 0px 0px 8px;
}
.projects .col-sm-6 {
  padding: 15px;
}
.projects .listBoxInner {
  border: 1px solid #008080;
  border-radius: 0px 10px 0px 10px;
  padding: 20px;
  position: relative;
  margin-bottom: 30px;
}
.projects .listBoxInner .profileInfo {
  align-items: center;
  justify-content: space-between;
  margin: 0px;
}
.projects .listBoxInner .profilePic {
  flex: 0 0 56px;
  max-width: 56px;
  height: 56px;
  border-radius: 50%;
  margin-right: 15px;
  padding: 0px;
}
.projects .listBoxInner .profileInfo ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  flex: 0 0 calc(100% - 71px);
  max-width: calc(100% - 71px);
  text-align: right;
}
.projects .listBoxInner .profileInfo ul li {
  display: none;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}
.projects .listBoxInner .profileInfo ul li span {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  display: inline-block;
  margin-right: 4px;
}
.projects .listBoxInner .profileInfo ul li.upcoming {
  background-color: #001f1f;
  color: #d29813;
}
.projects .listBoxInner .profileInfo ul li.upcoming span {
  background-color: #d29813;
}
.projects .listBoxInner .profileInfo ul li.saleLive {
  background-color: #d1fae5;
  color: #10b981;
}
.projects .listBoxInner .profileInfo ul li.saleLive span {
  background-color: #10b981;
}
.projects .listBoxInner .profileInfo ul li.saleEnded {
  background-color: #ffeaef;
  color: #ff3465;
}
.projects .listBoxInner .profileInfo ul li.saleEnded span {
  background-color: #ff3465;
}
.projects .listBoxInner .profileInfo ul li.active {
  display: inline-block;
}
.projects .listBoxInner h2 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  display: block;
}
.projects .listBoxInner .subtitle {
  font-size: 15px;
  font-weight: 600;
  color: #888;
  margin: 0px;
  display: block;
}
.projects .listBoxInner h3 {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  display: block;
}
.projects .listBoxInner strong {
  font-size: 20px;
  font-weight: 600;
  color: #01FFFF;
  margin: 5px 0px 10px;
  display: block;
}
.projects .listBoxInner .progressBar {
  margin: 3px 0 0px;
  font-size: 15px;
}
.projects .listBoxInner .progresLine {
  display: block;
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  background-color: rgba(72, 199, 116, 0.15);
  border-radius: 100px;
  height: 8px;
}
.projects .listBoxInner .progresLine span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(72, 199, 116);
  border-radius: 100px;
}
.projects .listBoxInner .tableBox p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  margin: 0 0 5px;
}
.projects .listBoxInner .tableBox p span {
  font-size: 15px;
  color: #888;
  font-weight: 600;
}
.projects .listBoxInner .tableBox p.ttl {
  color: #888;
  font-size: 13px;
  margin: 0 0 15px;
  font-weight: 600;
}
.projects .listBoxInner .tableBox p.ttl span {
  font-size: 13px;
}
.projects .listBoxInner .dividerLine {
  display: block;
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  background-color: #282a2a;
  margin-top: 5px;
}
.projects .listBoxInner .saleTineView {
  align-items: center;
  margin: 0px;
  z-index: 2;
  position: relative;
}
.projects .listBoxInner .saleTineView .timesSale {
  margin: 0px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 105px);
  max-width: calc(100% - 105px);
  margin-right: 15px;
  padding: 0px;
  font-size: 14px;
}
.projects .listBoxInner .saleTineView .timesSale span {
  color: #01FFFF;
  display: block;
  font-size: 14px;
  margin-top: 4px;
  letter-spacing: 1.2px;
  font-weight: 600;
}
.projects .listBoxInner .saleTineView .btnView {
  flex: 0 0 90px;
  max-width: 90px;
  text-align: center;
  background-color: #115C5F;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 5px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.projects .listBoxInner .saleTineView .react-countdown {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.projects .listBoxInner .saleTineView .react-countdown li {
  display: inline-block;
  margin-right: 4px;
}
.projects .listBoxInner .saleTineView .react-countdown li::after {
  top: 5px;
  font-size: 14px;
  right: -7px;
}
.projects .listBoxInner .saleTineView .react-countdown .digit {
  color: #01FFFF;
  margin: 5px 0px 10px;
  display: block;
  font-size: 14px;
  font-weight: bold;
}
.projects .listBoxInner .saleTineView .react-countdown .text {
  display: none;
}
.projects .listBoxInner::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  width: 65px;
  height: 75px;
  background-image: url("../../Assets/images/topBorder.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top left;
  opacity: 0.5;
  mix-blend-mode: difference;
}
.projects .listBoxInner::after {
  content: "";
  position: absolute;
  bottom: -4px;
  right: -4px;
  width: 70px;
  height: 50px;
  background-image: url("../../Assets/images/bottomBorder.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  opacity: 0.5;
  mix-blend-mode: difference;
}
.projects .listBoxInner:hover {
  border-color: #01FFFF;
}
.projects .listBoxInner:hover .saleTineView .btnView {
  background-color: #01FFFF;
  color: #001f1f;
  font-weight: 600;
}
.projects .listBoxInner:hover::before {
  opacity: 1;
  mix-blend-mode: unset;
}
.projects .listBoxInner:hover::after {
  opacity: 1;
  mix-blend-mode: unset;
}
.projects .listBoxInner.active {
  animation: blinkOcPublicBrn 1.5s infinite;
  transition: all 0.2s ease-in-out;
  box-shadow: none;
  text-shadow: none;
}
@keyframes blinkOcPublicBrn {
  0% {
    border-color: #125d60;
    opacity: 1;
    box-shadow: none;
    text-shadow: none;
  }
  49% {
    border-color: #02ffff;
    color: #02ffff;
    text-shadow: -0.2rem -0.2rem 1rem #ffffff, 0.2rem 0.2rem 1rem #ffffff, 0 0 2rem #02ffff, 0 0 4rem #02ffff, 0 0 6rem #02ffff, 0 0 8rem #02ffff, 0 0 10rem #02ffff;
    box-shadow: 0 0 0.1rem #125d60, inset 0 0 0.1rem #125d60, 0 0 2rem #125d60, inset 0 0 0rem #125d60, 0 0 1rem #125d60, inset 0 0 1rem #02ffff;
  }
  50% {
    border-color: #125d60;
    opacity: 1;
    box-shadow: none;
    text-shadow: none;
  }
}
.projects .listBoxInner.active .infoBox {
  text-shadow: none;
}

@media only screen and (max-width: 575px) {
  .projects .countBox .countinbox {
    margin-bottom: 20px;
    height: auto;
  }
}/*# sourceMappingURL=Projects.css.map */